import BaseModel from "./BaseModel";
import OrganizationModel from "./OrganizationModel";
import ModuleModel from "./ModuleModel";

class LockerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.hoursToReverse = data.hoursToReverse;
    this.containersCount = data.containersCount;

    this.organization = new OrganizationModel(data?.organization);
    this.modules =
      data?.modules?.length > 0
        ? data?.modules.map((i) => new ModuleModel(i))
        : [];
  }

  totalWidth() {
    let summedWidth = 0;

    for (let index = 0; index < this.modules.length; index++) {
      summedWidth = summedWidth + this.modules[index].width;
    }

    return summedWidth;
  }

  tallestModule() {
    return this.modules.reduce((prev, current) =>
      current.height > prev.height ? current : prev
    );
  }
}

export default LockerModel;
