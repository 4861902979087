import React from 'react';
import { Provider } from 'mobx-react';
import { ToastContainer, Slide } from 'react-toastify';
//Estilos
import '../../styles/build/css/Global.css';
import '../../libs/imports/bootstrap';
import '../../libs/imports/jquery';

import { initializeStores } from '~/stores/RootStore';
//Rotas
import Routes from '../../routes';

import MainContainer from '../../containers/MainContainer';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  const mobxStore = initializeStores().mobxStores;
  return (
    <div className="App">
      <Provider {...mobxStore}>
        <section className="MainSection">
          <MainContainer role="main">
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </MainContainer>

          <ToastContainer transition={Slide} />
        </section>
      </Provider>
    </div>
  );
};

export default App;
