import { extendObservable, makeObservable, action } from "mobx";

import CustomerAPI from "../services/CustomerAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import CustomerModel from "~/models/CustomerModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";
import UtilsAPI from "../services/UtilsAPI";
import CountryModel from "../models/CountryModel";

const initValues = {
  countries: [],
  loading: false,
};

class UtilsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.countries = [];
    this.loading = false;
  }

  async listCountries() {
    this.loading = true;
    const response = await UtilsAPI.listCountries();
    this.loading = false;
    if (!response.error) {
      this.countries = response.data.map((item) => ({
        value: new CountryModel(item),
        label: `${item.nameAbbreviation} (+${item.phoneDigit})`,
      }));
      return this.countries;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

}
makeObservable(UtilsStore, {
});

export default UtilsStore;
