import { extendObservable, makeObservable, action } from "mobx";

import CompanyFeaturesAPI from "../services/CompanyFeaturesAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import CompanyFeaturesModel from "~/models/CompanyFeaturesModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";


const initValues = {
  companyFeatures: undefined,
  loading: false,
};

class companyFeaturesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.companyFeatures = undefined;
    this.loading = false;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyCompanyFeatures() {
    this.companyFeatures = new CompanyFeaturesModel({});
  }

  onChange(prop, value) {
    if (!this.companyFeatures) this.companyFeatures = new CompanyFeaturesModel();

    switch (prop) {
      case 'primaryColor':
      case 'secondaryColor':
        this.companyFeatures[prop] = value.hex
        break;

      default:
        this.companyFeatures[prop] = value;
        break;
    }

    this.companyFeatures = new CompanyFeaturesModel({ ...this.companyFeatures })
  }


  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async get() {
    this.loading = true;


    const response = await CompanyFeaturesAPI.getCompanyFeatures();
    this.loading = false;
    if (!response.error) {
      this.companyFeatures = new CompanyFeaturesModel(response);
      return this.companyFeatures;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Atualiza configurações de empresa.
   */
  async onUpdate() {
    this.loading = true;

    const response = await CompanyFeaturesAPI.updateCompanyFeatures(this.companyFeatures);

    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Configurações de empresa atualizadas com sucesso"
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

}
makeObservable(companyFeaturesStore, {
});

export default companyFeaturesStore;
