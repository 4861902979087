import { extendObservable, makeObservable, action } from "mobx";

import LockerAPI from "../services/LockerAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import LockerModel from "~/models/LockerModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";

const initValues = {
  locker: undefined,
  lockers: [],
  lockersAsyncSelect: [],
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,
  loading: false,
};

class LockerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.locker = undefined;
    this.lockers = [];
    this.lockersAsyncSelect = [];
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;
    this.loading = false;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyLocker() {
    this.locker = new LockerModel({});
  }

  onChange(prop, value) {
    if (!this.locker) this.locker = new LockerModel();

    switch (prop) {
      default:
        this.locker[prop] = value;
    }
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;

    if (!uuid) uuid = StorageUtil.getItem(KEYS.USER_KEY);

    const response = await LockerAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.locker = new LockerModel(response);
      return this.locker;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async getList(params) {
    this.loading = true;

    params = {
      page: params?.page ?? this.page,
      filter: params?.filter ?? '',
    };
    setURLSearchParams(params.page, params.filter)

    const response = await LockerAPI.getList({ page: params.page, filter: convertObjectToFilter(params.filter) });
    this.loading = false;
    if (!response.error) {
      this.lockers = response.content.map((locker) => new LockerModel(locker));
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;

      return this.lockers;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Atualiza dados de um usuario separador.
   * @param  {string} uuid
   */
  async onUpdate(uuid) {
    this.loading = true;

    const response = await LockerAPI.update(uuid, this.locker);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Locker atualizado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getLockersAsyncSelect(name) {
    const filter = [
      `name__icontains=${name}`,
      "features__preRegisteredAllocateFlow__isnull=False",
    ];
    const response = await LockerAPI.getList({ filter });

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  async export(params) {
    this.loading = true;

    params = {
      filter: convertObjectToFilter(params?.filter),
    };

    const response = await LockerAPI.export(params);
    this.loading = false;
    if (!response.error) {
      exportCSV(response, "lockers");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}
makeObservable(LockerStore, {
});

export default LockerStore;
