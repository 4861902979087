
export const flatParamValue = (value) => {
  // Caso sem valor, retornar string vazia
  if (!value) return ''

  // Caso for objeto
  if (typeof value === 'object'){

    // Caso objeto tiver a propriedade value 
    if ("value" in value){

      // Retorna value tiver valor, caso contrario retorna string vazia
      return value["value"] ?? ''
    }

    // Se objeto nao tiver propriedade value retorna string vazia
    return ''

    // Caso value nao for objeto, retorna o que for
  } else {
    return value
  } 
}