/**
 * Método Assincrono que retorna algorítimo SHA-512 que vai criptografar a senha do usuário
 * @returns Retorna uma promise
 * @param  {string} pass password
 */
export async function cryptoPass(pass) {
  const SHA512 = require("crypto-js/sha512");
  const arrayResult = SHA512(pass).words;
  const byteArray = new Uint32Array(arrayResult);
  const hexCodes = [...byteArray].map((value) => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, "0");
    return paddedHexCode;
  });
  return hexCodes.join("");
}

/**
/**Verifica se possui um callback válido e devolve para quem chamou
 * @param  {string} e eventClick
 * @param  {function} callback funcao de retorno
 * @param  {object} data objeto que será devolvido
 */
export function clickGeneric(e, callback, data) {
  if (callback) {
    callback(e, data);
  }
}

/**Return numbers without characteres */
export const onlyNumbers = (value) => (value ? value.replace(/\D/g, "") : "");

//**Used on filter of array to distinct values */
export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const currencyToNumber = (value, currency = "R$") => {
  const string = value.replace(".", "").replace(",", ".").replace(currency, "");
  return Number(string);
};

export const toFloat = (value, toReplace = "") => {
  const string = value
    .replace(".", "")
    .replace(",", ".")
    .replace(toReplace, "");
  return parseFloat(string);
};

export const validateImgLink = (value) => {
  const reg =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?|magnet:\?xt=urn:btih:/;
  if (reg.test(value)) {
    return true;
  }
  return false;
};

/**onChangeValue need to be a function */
export const onChangeValue = (e, prop, obj = this) => {
  obj[prop] = e.target.value;
};

/*
 * Transforma uma data em uma string no formato YYYY-MM-DD
 */
export function dateToText(value, separator = "/", reverse = false) {
  if (!value) return "";
  const date = new Date(value);

  const month = `${"0"}${date.getMonth() + 1}`.slice(-2);
  const day = `${"0"}${date.getDate()}`.slice(-2);

  if (reverse) {
    return `${day}${separator}${month}${separator}${date.getFullYear()}`;
  }

  return `${date.getFullYear()}${separator}${month}${separator}${day}`;
}

export function formatISODate(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function formatToISODate(dateString) {
  const regex = /^\d{2}\/\d{2}\/\d{4} (\d{2}:\d{2}:\d{2})?$/;
  if (!dateString || !regex.test(dateString)) return "";

  const [formattedDate, formattedTime] = dateString.split(" ");

  const [day, month, year] = formattedDate.split("/");
  const [hours, minutes, seconds] = formattedTime.split(":");

  const isoDate = new Date(
    year,
    month - 1,
    day,
    hours ?? 0,
    minutes ?? 0,
    seconds ?? 0
  ).toISOString();
  return isoDate;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return;
  // Remove non-digit characters from the phone number
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Apply the desired format
  const formatted = `(${cleaned.substring(0, 2)}) ${cleaned.substring(
    2,
    7
  )}-${cleaned.substring(7)}`;

  return formatted;
}

/*
 * Dado uma string válida, retorna uma data.
 */
export function textToDate(dateStr) {
  const [day, month, year] = dateStr.split("/");
  const date = new Date(year, month - 1, day);
  return date;
}

/**Retorna data convertida
 * 29 de Agosto de 2019 *
 */
export const convertDate = (dateConvert) => {
  let date;
  if (dateConvert instanceof Date) {
    date = dateConvert;
  } else date = new Date(dateConvert);
  // requer um dia da semana jutamente com uma data longa
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("pt-BR", options);
};

/**Converte valor float em string*/
export const convertPrice = (value = 0, currency = "BRL") =>
  value.toLocaleString("pt-BR", {
    style: "currency",
    currency,
  });

/**Formata moedas */
export const formatNumbers = (value, prefix = "", suffix = "") => {
  if (!value) {
    return prefix.concat("0,00").concat(suffix);
  }

  if (!Number.isNaN(value)) {
    if (value.parseFloat) {
      value = value.parseFloat(value).toFixed(2);
    } else if (value.toFixed) {
      value = value.toFixed(2);
    }
  }

  let v = value.toString().replace(/\D/g, "");
  v = `${(v / 100).toFixed(2)}`;
  v = v.replace(".", ",");
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
  return prefix.concat(v).concat(suffix);
};

/**Decode base64 */
export const decodeJWT = (token) => {
  const base64Url = token.split(".")[1];
  return JSON.parse(window.atob(base64Url));
};
/**Rememove  */
export const removeItemList = (list = [], item) => {
  list.splice(list.indexOf(item), 1);
};

/**Sort listBy prop */
export const sortListBy = (list, prop, order) =>
  list.sort((a, b) => {
    a = a[prop];
    b = b[prop];
    const value = order === "cres" ? -1 : 1;
    if (a < b) {
      return value;
    }
    if (a > b) {
      return value * -1;
    }
    return 0;
  });

export const isArrayEmpty = (array) => {
  if (!array) {
    return true;
  }

  if (array.length > 0) {
    return false;
  }

  return true;
};

export const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }
  return false;
};

export const checkIfContainsAllItems = (
  arr,
  target,
  arrPropName,
  targetPropName
) => {
  if (!arr || !target) return false;

  const isValid = target.every(
    (v) => arr.findIndex((f) => f[arrPropName] === v[targetPropName]) > -1
  );

  return isValid;
};

export const onlyUniqueByProp = (value, index, self, propName) =>
  self.findIndex((t) => t[propName] === value[propName]) === index;

export const numberIsEmptyOrZero = (value) => {
  if (!value) {
    return true;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  let valueToCheck;

  if (typeof value === "string") {
    value = value.replace(",", ".");

    valueToCheck = Number.parseFloat(value);
  } else if (typeof value !== "number") {
    return false;
  }

  if (valueToCheck === 0) {
    return true;
  }

  return false;
};

export function simpleDateToText(value) {
  if (!value) return "";

  return value.substring(0, 10).split("-").reverse().join("/");
}

export const getDate = (dateValue) => {
  if (!dateValue) return undefined;
  if (dateValue.length < 10) return undefined;

  let separator;
  let reversed;

  if (dateValue.includes("-")) {
    separator = "-";
    reversed = true;
  } else if (dateValue.includes("/")) {
    separator = "/";
    reversed = false;
  }

  const values = dateValue.split(separator);

  let value;

  if (!reversed) {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[0]} ${values[2]}`);
  } else {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[2]} ${values[0]}`);
  }

  if (Number.isNaN(value.getDate())) {
    return undefined;
  }

  return value;
};

/* Result 23:59 */
export function getHoursAndMinutes(date) {
  date = new Date(date);
  const hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  return `${hours}:${minutes}`;
}

export const toDecimal = (value, decimalPlaces = 2) => {
  const string = value.replace(".", "").replace(",", ".");
  return parseFloat(string).toFixed(decimalPlaces);
};

export const validateDate = (dateValue) => {
  if (!dateValue) return false;
  if (dateValue.length < 10) return false;

  let separator;
  let reversed;

  if (dateValue.includes("-")) {
    separator = "-";
    reversed = true;
  } else if (dateValue.includes("/")) {
    separator = "/";
    reversed = false;
  }

  const values = dateValue.split(separator);

  let value;

  if (!reversed) {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[0]} ${values[2]}`);
  } else {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[2].substring(0, 2)} ${values[0]}`);
  }

  if (Number.isNaN(value.getDate())) {
    return false;
  }

  return true;
};

export const clearCPFAndCNPJMask = (value) => {
  if (value) {
    return value.replace(/\D/g, "");
  }

  return value;
};

export const sortByDate = (array) => {
  const sortedArray = array.sort(
    (a, b) => new Date(a.created) - new Date(b.created)
  );

  return sortedArray;
};

/**Converte Data yyyy/mm/dd para dd/mm */
export const dateConvert = (date) => {
  if (!date) {
    return undefined;
  }

  const datePart = date.match(/\d+/g);
  const month = datePart[1];
  const day = datePart[2];
  const years = datePart[0];

  return `${day}/${month}${years}`;
};

export function simpleDateTimeToText(value) {
  if (!value) return "";

  const dateTimeSplited = value.split("T");

  let date;

  if (dateTimeSplited.length > 1) {
    date = new Date(value);
  } else {
    date = new Date(`${value}T00:00:00`);
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${day}/${month}/${year}`;
}

export const zipCodeConvert = (zipCode) => {
  if (!zipCode) return "";

  if (zipCode.length < 8) return zipCode;

  zipCode = zipCode.toString();
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  if (re.test(zipCode)) {
    return zipCode.replace(re, "$1$2-$3");
  }

  return "";
};
export const aliasPaymentType = (name) => {
  switch (name) {
    case "INVOICE":
      return "Boleto";
    case "BANK_TRANSFER":
      return "Transfêrencia Bancária";
    case "CREDIT_CARD":
      return "Cartão de Crédito";
    case "PIX":
      return "PIX";
    default:
      return "Outros";
  }
};

export const getHoursFromBranchOpeningHours = ({
  openingHoursString,
  isOpen,
}) => {
  const time = openingHoursString
    ?.split("as")
    [isOpen ? 0 : 1]?.replace(/\s/g, "");

  return time;
};

export const getHoursFromStoreOpeningHours = ({
  businessHoursString,
  isOpen,
}) => {
  return isOpen
    ? businessHoursString?.split(",")[0].split(" - ")[0]
    : businessHoursString?.split(",")[1].split(" - ")[1];
};

/**Retorna data convertida
 * pega o valor 23/12/2023 *
 * converte para 2023-12-23
 */
export const convertDateFormat = (dateConvert) => {
  var arr1 = dateConvert.split("/");
  var newDate = `${arr1[2]}-${arr1[1]}-${arr1[0]}`;

  return newDate;
};
