import BaseAPI, { URLS } from "./BaseAPI";

class OrganizationAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que retorna alocação a partir de code e uuid de locker
   * @param  {string} data - objeto de allocation
   */
  static async list(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/`, params);
      if (response.status === 200) return response.data;
      return { error: "Alocações não encontradas." };
    } catch (e) {
      return this._exception(e, "Alocações não encontrada.");
    }
  }

  /**
   * Request que retorna alocação a partir de code e uuid de locker
   * @param  {string} data - objeto de allocation
   */
  static async getAllocationByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "alocação não encontrada." };
    } catch (e) {
      return this._exception(e, "alocação não encontrada.");
    }
  }

  /**
   * Request que retorna alocação a partir de code e uuid de locker
   * @param  {string} data - objeto de allocation
   */
  static async getAllocationByCodeAndLockerUuid(code, lockerUuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/by-code/${code}`, {
        locker: lockerUuid,
      });
      if (response.status === 200) return response.data;
      return { error: "alocação não encontrada." };
    } catch (e) {
      return this._exception(e, "alocação não encontrada.");
    }
  }

  /**
   * Request que retorna alocaçoes em regra de reversa ou cancelados
   * @param  {string} uuid - uuid de locker
   */
  static async getLockerReverseList(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ALLOCATION}/locker/${uuid}/reverse-list`
      );
      if (response.status === 200) return response.data;
      return { error: "Locker sem reversas" };
    } catch (e) {
      return this._exception(e, "Locker sem reversas");
    }
  }

  /**
   * Request que retorna alocaçoes em regra de reversa ou cancelados
   * @param  {string} uuid - uuid de locker
   */
  static async getInLockerList(uuid, params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ALLOCATION}/locker/${uuid}/in-locker`,
        params
      );
      if (response.status === 200) return response.data;
      return { error: "Locker sem alocaçãos" };
    } catch (e) {
      return this._exception(e, "Locker sem alocaçãos");
    }
  }

  /**
   * Request que registra alocaçãof
   * @param  {string} data - objeto de allocation
   */
  static async create(data) {
    let url = `${URLS.ALLOCATION}/`;

    // if (data.containerAllocations.length > 0)
    //   url = `${url}?random_external_code=true`;

    try {
      const response = await BaseAPI.post(url, data);
      if (response.status === 201) return response.data;
      return { error: "Erro ao registrar alocação." };
    } catch (e) {
      return this._exception(e, "Falha ao registrar alocação.");
    }
  }

  /**
   * Request que registra alocação
   * @param  {string} data - objeto de allocation
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ALLOCATION}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Erro ao atualizar alocação." };
    } catch (e) {
      return this._exception(e, "Falha ao registrar alocação.");
    }
  }

  /**
   * @param  {string} uuid Uuid da alocacao que será excluída
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ALLOCATION}/${uuid}`);
      if (response.status === 200 || response.status === 204)
        return response.data;
      return { error: "Não foi possível excluir a alocação" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * Request que registra alocação
   * @param  {string} data - objeto de allocation
   */
  static async allocate(uuid, data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/allocate`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao registrar alocação." };
    } catch (e) {
      return this._exception(e, "Falha ao registrar alocação.");
    }
  }

  /**
   * Request que registra alocação
   * @param  {string} data - objeto de allocation
   */
  static async allocationFinished(uuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/allocation-finished`
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao registrar conclusao de alocação de pedido." };
    } catch (e) {
      return this._exception(
        e,
        "Falha ao registrar conclusao de alocação de pedido."
      );
    }
  }

  /**
   * Request que registra alocação
   * @param  {string} data - objeto de allocation
   */
  static async deallocate(uuid, data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/deallocate`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao registrar retirada." };
    } catch (e) {
      return this._exception(e, "Falha ao registrar retirada.");
    }
  }

  /**
   * Request que registra alocação
   * @param  {string} data - objeto de allocation
   */
  static async reverse(uuid, data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/reverse`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao registrar reversa." };
    } catch (e) {
      return this._exception(e, "Falha ao registrar reversa.");
    }
  }

  /**
   * Request que registra alocação
   * @param  {string} data - objeto de allocation
   */
  static async getAllocationStatuses() {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/statuses`);
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar status." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar status.");
    }
  }

  static async export(params) {
    try {
      const response = await BaseAPI.get(URLS.ALLOCATION + "/csv", params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }

  /**
   * Request que envia mensagem de nova alocação
   * @param  {string} uuid - uuid de allocation
   */
  static async sendMessageNewAllocation(uuid) {
    try {
      const response = await BaseAPI.post(
        `${URLS.ALLOCATION}/${uuid}/send-message-new-allocation`
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao enviar mensagem de nova alocação." };
    } catch (e) {
      return this._exception(e, "Falha ao enviar mensagem de nova alocação.");
    }
  }

  /**
   * Request que envia e-mail alocação
   * @param  {string} uuid - uuid de allocation
   */
  static async sendEmailNewAllocation(uuid) {
    try {
      const response = await BaseAPI.post(
        `${URLS.ALLOCATION}/${uuid}/send-email-new-allocation`
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao enviar e-mail de nova alocação." };
    } catch (e) {
      return this._exception(e, "Falha ao enviar e-mail de nova alocação.");
    }
  }
}

export default OrganizationAPI;
