import BaseAPI, { URLS } from "./BaseAPI";

class SeparatorAPI {
  /**
   * @param  {Object} data Dados do novo usuário separador
   */
  static async createSeparator(data) {
    try {
      const response = await BaseAPI.post(`${URLS.USER}separator`, data);
      return response.status === 201
        ? response.data
        : { error: "Falha ao cadastrar admin" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao cadastrar separador" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateSeparator(uuid, data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.USER}${uuid}/separator/`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do separador" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async deleteSeparator(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.USER}${uuid}/separator`);
      if (response.status === 200 || response.status === 204)
        return response.data;
      return { error: "Não foi possível excluir o separador" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * Busca um usuário admin por username
   * @param  {string} userName username do usuário a ser buscado
   */
  static async getSeparator(uuid) {
    try {
      const url = `${URLS.USER}${uuid}/separator`;
      const response = await BaseAPI.get(url);
      if (response.data.status) {
        return { error: "Falha ao buscar separador" };
      }
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar separador" };
    }
  }

  /**Busca todos os admins */
  static async list(params) {
    try {
      const response = await BaseAPI.get(`${URLS.USER}separator`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar separador" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar separador" };
    }
  }

  static async export(params) {
    try {
      const response = await BaseAPI.get(URLS.USER + "separator/csv", params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }
}

export default SeparatorAPI;
