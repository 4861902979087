import BaseModel from "./BaseModel";

class CompanyFeaturesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.onScreenName = data.onScreenName;
    this.primaryColor = data.primaryColor;
    this.secondaryColor = data.secondaryColor;
    this.logo = data.logo;
    this.secondaryLogo = data.secondaryLogo;
  }
}

export default CompanyFeaturesModel;
