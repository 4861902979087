import BaseAPI, { URLS } from "./BaseAPI";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

/**AuthRequests é a camada onde incluímos a lógica que conversa direto com o backend e devolve o dado para  store.. */
class AuthAPI {
  static async mfaRequest(data) {
    try {
      const response = await BaseAPI.post(URLS.MFA, data);
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Erro ao solicitar 2FA" };
    }
  }

  /**Autentica o usuário e grava o token no localstorage */
  static async login(data) {
    try {
      const response = await BaseAPI.post(URLS.LOGIN, data);
      StorageUtil.setItem(KEYS.USER_KEY, response.data.uuid);
      return response;
    } catch (e) {
      console.log(e.message);
      return { error: "Não autenticado" };
    }
  }

  /**Logout - desloga o usuário no backend e remove do localstorage
   * OBS: Hoje não possuímos nenhum endpoint para invalidar o token. Portanto estamos só removendo do localStorage.
   * Se amanhã ou depois vier a possuir algo do lado do back a estrutura já está pronta e deverá ser trabalhado aqui.
   */
  static async logout() {
    StorageUtil.cleanAll();
    return true;
  }

  /**Faz requisição de redefinição de senha do email informado */
  static async redefinePass(email) {
    try {
      const response = await BaseAPI.post(URLS.FORGET_PASSWORD, { email });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }

  static async validateToken(token) {
    try {
      const response = await BaseAPI.post(
        `${URLS.FORGET_PASSWORD}validate_token/`,
        { token }
      );
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }

  static async changePassword(token, password) {
    try {
      const response = await BaseAPI.post(`${URLS.FORGET_PASSWORD}confirm/`, {
        token,
        password,
      });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }
}

export default AuthAPI;
