import BaseAPI, { URLS } from "./BaseAPI";

class CustomerAPI {
  /**
   * @param  {Object} data Dados do novo usuário cliente
   */
  static async createCustomer(data) {
    try {
      const response = await BaseAPI.post(`${URLS.USER}customer`, data);
      return response.status === 201
        ? response
        : { error: "Falha ao cadastrar admin" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao cadastrar cliente" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateCustomer(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.USER}${uuid}/customer/`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do cliente" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async deleteCustomer(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.USER}${uuid}/customer`);
      if (response.status === 200 || response.status === 204)
        return response.data;
      return { error: "Não foi possível excluir o cliente" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * Busca um usuário admin por username
   * @param  {string} userName username do usuário a ser buscado
   */
  static async getCustomer(uuid) {
    try {
      const url = `${URLS.USER}${uuid}/customer`;
      const response = await BaseAPI.get(url);
      if (response.data.status) {
        return { error: "Falha ao buscar cliente" };
      }
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar cliente" };
    }
  }

  /**Busca todos os admins */
  static async list(params) {
    try {
      const response = await BaseAPI.get(`${URLS.USER}customer`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar cliente" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar cliente" };
    }
  }

  static async export(params) {
    try {
      const response = await BaseAPI.get(URLS.USER + "customer/csv", params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }

  static async import(data) {
    try {
      const response = await BaseAPI.post(URLS.USER + "customer/csv", data);
      if (response.status === 201) return true;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }
}

export default CustomerAPI;
