import BaseModel from "./BaseModel";
import OrganizationModel from "./OrganizationModel";

/**Modelo usuário */
class UserModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.email = data.email;
    this.countryCode = data.countryCode;
    this.phone = data.phone;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.fullName = data.fullName;
    this.password = data.password;
    this.organization = new OrganizationModel(data.organization);
  }
}

export default UserModel;
