class PermissionModel {
  constructor(data = {}) {
    if (!data) return;

    this.id = data.id;
    this.name = data.name;
    this.codename = data.codename;
  }
}

export default PermissionModel;
