import BaseModel from "./BaseModel";

class StatusModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.code = data.code;
    this.name = data.name;
  }
}

export default StatusModel;
