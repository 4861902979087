import FileSaver from "file-saver";
import { dateToText } from "./Functions";

export const exportCSV = async (csvContent, fileNameSufix) => {
  const csvData = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const fileName =
    fileNameSufix + "_" + dateToText(new Date(), "_", true) + ".csv";

  FileSaver.saveAs(csvData, fileName);
};
