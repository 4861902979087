import BaseAPI, { URLS } from "./BaseAPI";

class SeparatorAPI {
  /**
   * @param  {Object} params query params
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.PERMISSION}/`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao listar permissões" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao listar permissões" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário
   * @param {object} params filtros para listagem
   */
  static async getListUserPermissions(uuid, params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.PERMISSION}/user/${uuid}`,
        params
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao listar permissões do usuário" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  static async getOwnUserPermissions() {
    try {
      const response = await BaseAPI.get(`${URLS.PERMISSION}/own`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao listar permissões do usuário" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário
   * @param {object} data permissões a serem definidas para usuário
   */
  static async setUserPermissions(uuid, data) {
    try {
      const response = await BaseAPI.post(
        `${URLS.PERMISSION}/user/${uuid}`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao definir permissões do usuário" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }
}

export default SeparatorAPI;
