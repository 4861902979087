const OrganizationPermissionEnum = Object.freeze({
  GET: "view_organization",
  POST: "add_organization",
  PUT: "change_organization",
  DELETE: "delete_organization",
  EXPORT: "can_export_organization",
  IMPORT: "can_import_organization",
});

export default OrganizationPermissionEnum;
