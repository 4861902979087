import BaseModel from "./BaseModel";
import ContainerModel from "./ContainerModel";

class ModuleModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.locker = data?.locker;

    this.name = data?.name;
    this.ordering = data?.ordering;
    this.height = data?.height;
    this.width = data?.width;

    this.containers =
      data?.containers.length > 0
        ? data?.containers.map((i) => new ContainerModel(i))
        : [];
  }
}

export default ModuleModel;
