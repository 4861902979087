import { flatParamValue } from "./FlatParamValue";

export const setURLSearchParams = (page, filter) => {
  const url = new URL(window.location.origin + window.location.pathname);
    Object.keys(filter).forEach((itemFilter)=>{
      const _value = flatParamValue( filter[itemFilter])
      if (_value!== ''){
        url.searchParams.set(itemFilter, _value);
      }
    })
    url.searchParams.set('page', page);
    window.history.pushState({}, "", url.toString());
}