import { flatParamValue } from "./FlatParamValue";
export function convertObjectToFilter(objeto) {
  // Converte o objeto em uma matriz de pares chave-valor
  const filteredEntries = Object.entries(objeto)
    // Filtra os pares vazios e aplica flatParamValue nos valores
    .map(([key, value]) => [key, flatParamValue(value)])
    .filter(([key, value]) => value !== "");

  // Mapeia os pares chave-valor não vazios para o formato "chave=valor".
  const filter = filteredEntries.map(([key, value]) => `${key}=${value}`);

  return filter;
}