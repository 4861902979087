import BaseAPI, { URLS } from "./BaseAPI";

class CustomerAPI {

  /**
   * Busca configurações da empresa
   */
  static async getCompanyFeatures() {
    try {
      const url = `${URLS.COMPANY}features`;
      const response = await BaseAPI.get(url);
      if (response.data.status) {
        return { error: "Falha ao buscar configurações da empresa" };
      }
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar configurações da empresa" };
    }
  }

  /**
   * Atualiza
   */
  static async updateCompanyFeatures(data) {
    const formData = new FormData();

    formData.append('onScreenName', data.onScreenName)
    formData.append('primaryColor', data.primaryColor)
    formData.append('secondaryColor', data.secondaryColor)

    if (data.logo instanceof File)
      formData.append('logo', data.logo, data.logo.name)

    if (data.secondaryLogo instanceof File)
      formData.append('secondaryLogo', data.secondaryLogo, data.secondaryLogo.name)

    data = formData

    try {
      const url = `${URLS.COMPANY}features`;
      const response = await BaseAPI.put(url, data);
      if (response.data.status) {
        return { error: "Falha ao atualizar configurações da empresa" };
      }
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao atualizar configurações da empresa" };
    }
  }
}

export default CustomerAPI;
