//import { dateToText } from "~/helpers/utils/Functions";

export default class BaseModel {
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
    this.dateCreated = data.dateCreated;
    this.dateModified = data.dateModified;
    this.isActive = data.isActive !== undefined ? data.isActive : true;
  }
}
