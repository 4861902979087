import BaseModel from "./BaseModel";

class ContainerMeasureModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.height = data.height;
    this.width = data.width;
    this.depth = data.depth;
  }
}

export default ContainerMeasureModel;
