/* eslint-disable class-methods-use-this */
import { extendObservable } from "mobx";

import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import ItemMenuModel from "~/models/ItemMenuModel";

import OrganizationPermissionsEnum from "../models/permissions/OrganizationPermissions";
import AdministratorPermissionsEnum from "../models/permissions/AdministratorPermissions";
import CustomerPermissionsEnum from "../models/permissions/CustomerPermissions";
import SeparatorPermissionsEnum from "../models/permissions/SeparatorPermissions";
import CompanyFeaturesPermissionEnum from "../models/permissions/CompanyFeaturesPermissions";

// const ManagerPropertyContainer = lazy(() => import('../containers/ManagerPropertyContainer'));

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
      drawerOpen: false,
    });
  }

  setDrawer(value) {
    this.drawerOpen = value;
  }

  /** Carrega menu */
  loadMenu() {
    let items = this.itemsMenu;

    items = this.filterMenuByPermissions(items);

    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  filterMenuByPermissions(itemsMenu) {
    let itemsMenuValidated = itemsMenu.filter((itemMenu) =>
      itemMenu.permissions
        ? this.rootStore.permissionStore.validatePermissions(
          itemMenu.permissions
        )
        : true
    );

    for (
      let indexItemMenu = 0;
      indexItemMenu < itemsMenuValidated.length;
      indexItemMenu++
    ) {
      let itemMenu = itemsMenuValidated[indexItemMenu];

      itemMenu.subItems = itemMenu.subItems.filter((subItemMenu) => {
        let result;
        subItemMenu.permissions?.length > 0
          ? (result = this.rootStore.permissionStore.validatePermissions(
            subItemMenu.permissions
          ))
          : (result = true);

        return result;
      });

      itemsMenuValidated[indexItemMenu] = itemMenu;
    }

    return itemsMenuValidated;
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  get dashboardData() {
    return {
      title: "Dashboard",
      subtitle: "Um painel completo para controlar seu site",
      icon: "fas fa-clipboard-list",
      subItems: [],
    };
  }

  /** Items de menu */
  get itemsMenu() {
    return Object.values({
      configurations: {
        title: "Configurações",
        icon: "fas fa-wrench",
        permissions: [CompanyFeaturesPermissionEnum.PUT],
        subItems: [
          {
            path: "/configuracoes/aplicacao",
            title: "Aplicação",
            permissions: [CompanyFeaturesPermissionEnum.PUT],
          }
        ]
      },
      organizations: {
        title: "Organizações",
        subtitle: "Área de organizações",
        icon: "fas fa-building",
        subItems: [
          {
            path: "/cadastrar-organizacao",
            title: "Nova Organização",
            permissions: [OrganizationPermissionsEnum.POST],
          },
          {
            path: "/organizacoes",
            title: "Organizações",
          },
        ],
      },
      administrators: {
        title: "Administradores",
        subtitle: "Área de administradores",
        icon: "fas fa-user-shield",
        subItems: [
          {
            path: "/cadastrar-administrador",
            title: "Novo Administrador",
            permissions: [AdministratorPermissionsEnum.POST],
          },
          { path: "/administradores", title: "Administradores" },
        ],
      },

      // TODO LIDAR COM MODULOS SEM PERM QUE DEVEM SER LISTADOS, TELAS DE LISTAGEM POR EX

      separators: {
        title: "Separadores",
        subtitle: "Área de separadores",
        icon: "fas fa-dolly",
        subItems: [
          {
            path: "/cadastrar-separador",
            title: "Novo Separador",
            permissions: [SeparatorPermissionsEnum.POST],
          },
          { path: "/separadores", title: "Separadores" },
        ],
      },
      customers: {
        title: "Clientes",
        subtitle: "Área de clientes",
        icon: "fas fa-user",
        subItems: [
          {
            path: "/cadastrar-cliente",
            title: "Novo Cliente",
            permissions: [CustomerPermissionsEnum.POST],
          },
          { path: "/clientes", title: "Clientes" },
        ],
      },
      lockers: {
        title: "Gerenciamento de Locker",
        subtitle: "Lockers",
        icon: "fas fa-box",
        subItems: [{ title: "Lockers", path: "/lockers" }],
      },
      allocations: {
        title: "Alocações",
        subtitle: "Alocações",
        icon: "fas fa-boxes",
        subItems: [{ title: "Alocações", path: "/alocacoes" }],
      },
    });
  }
}

export default MenuStore;
