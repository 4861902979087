import BaseAPI, { URLS } from "./BaseAPI";

class LockerAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que busca locker por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.LOCKER}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar locker." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar locker.");
    }
  }

  /**
   * Request que busca locker por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.LOCKER}/`, params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao listar lockers." };
    } catch (e) {
      return this._exception(e, "Falha ao listar lockers.");
    }
  }

  /**
   * Request que atualiza dados da locker
   * @param  {string} uuid - uuid do usuário
   * @param  {object} data - dados da locker
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.LOCKER}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar locker." };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, "Falha ao atualizar locker.");
    }
  }

  static async export(params) {
    try {
      const response = await BaseAPI.get(URLS.LOCKER + "/csv", params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }
}

export default LockerAPI;
