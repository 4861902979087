import { extendObservable, makeObservable, action } from "mobx";
import AuthAPI from "../services/AuthAPI";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
/**
 * Store que trata toda lógica de autenticação do usuário.
 */
class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      isAuthenticated: false,
      loginAuthenticated: false,
      loading: false,
      authoritie: false,
      email: "",
      password: "",
      token: "",
      remember: StorageUtil.getItem(KEYS.REMEMBER),
      user: undefined,
    });

    if (this.remember === "true") this.autoLogin();
  }

  /**Verifica se os dados salvos na storage são válidos e faz o login automaticamente.*/
  async autoLogin() {
    this.loading = true;
    //busca usuário salvo no localstorage,
    //caso exista verifica se as credenciais ainda são válidas
    const savedUser = StorageUtil.getItem(KEYS.USER_KEY);
    if (savedUser) {
      const result = await this.rootStore.administratorsStore.get(savedUser);
      this.isAuthenticated = !result.error && true;
      this.user = result;
      if (!this.isAuthenticated) {
        StorageUtil.cleanAll();
      }
    }
    this.loading = false;
  }

  /**
   * Valida se apresenta pop-up de lembrete de atualizacao cadastral
   * **/
  async validateLastWarningDate() {
    var lastWarningDate = StorageUtil.getItem(KEYS.LAST_WARNING_DATE);
    const currentDate = new Date();

    if (lastWarningDate) {
      lastWarningDate = new Date(lastWarningDate);

      if (currentDate.getMonth() === lastWarningDate.getMonth()) {
        return true;
      }
    }

    StorageUtil.setItem(KEYS.LAST_WARNING_DATE, currentDate);
    this.toastHelper.notify(
      STATUS_HELPER.WARNING,
      "Lembre-se de sempre manter os cadastros atualizados!",
      false
    );
  }

  get isStoomAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "STOOM";
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN";
  }

  /**Verifica se a credencial do usuário é de admin */
  get isAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "ADMIN";
  }

  emailSave(value) {
    this.email = value;
  }

  async mfaRequest() {
    this.loading = true;
    const data = {
      email: this.email,
    };
    const result = await AuthAPI.mfaRequest(data);
    this.loginAuthenticated = !result.error && true;
    if (!result.error) {
      // this.toastHelper.notify(
      //   STATUS_HELPER.SUCCESS,
      //   "E-mail enviado com sucesso"
      // );
    } else {
      StorageUtil.cleanAll();
      this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
    }
    this.loading = false;
    return this.loginAuthenticated;
  }

  /** Faz o login */
  async login(token, password) {
    this.loading = true;

    const payload = {
      email: this.email,
      password,
      token,
    };

    const result = await AuthAPI.login(payload);
    this.isAuthenticated = !result.error && true;
    if (!this.isAuthenticated) {
      StorageUtil.cleanAll();
      this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
    } else {
      const result = await this.rootStore.administratorsStore.get(
        StorageUtil.getItem(KEYS.USER_KEY)
      );
      this.user = result;
      this.validateLastWarningDate();
    }
    this.loading = false;
    return this.isAuthenticated;
  }

  /**Logout */
  async logout() {
    this.loading = true;
    await AuthAPI.logout();
    this.isAuthenticated = false;
    this.loading = false;
    return this.isAuthenticated;
  }

  /**Envia */
  async redefinePass(email) {
    const response = await AuthAPI.redefinePass(email);
    return response;
  }

  async validateToken(token) {
    const response = await AuthAPI.validateToken(token);
    return response;
  }

  async changePassword(token, newPassword) {
    const response = await AuthAPI.changePassword(token, newPassword);
    if (response.error) {
    }
    return response;
  }
}

export default AuthStore;

makeObservable(AuthStore, {

});
