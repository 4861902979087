import UserModel from "./UserModel";

class SeparatorModel extends UserModel {
  constructor(data = {}) {
    super(data);
    this.qrCode = data.qrCode;
  }

  get isSuperAdmin() {
    return this.user.role === "STOOM" || this.user.role === "SUPERADMIN";
  }
}

export default SeparatorModel;
