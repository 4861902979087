import BaseModel from "./BaseModel";

class CountryModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.mask = data.mask;
    this.nameAbbreviation = data.nameAbbreviation;
    this.name = data.name;
    this.phoneDigit = data.phoneDigit;
  }
}

export default CountryModel;
