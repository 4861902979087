import BaseModel from "./BaseModel";

class AddressModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.street = data.street;
    this.number = data.number;
    this.district = data.district;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country || "Brazil";
    this.complement = data.complement;
  }
}

export default AddressModel;
