import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoadingComponent from "~/components/LoadingComponent/LoadingComponent";

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import("~/pages/HomePage/HomePage"));
const NotFound = lazy(() => import("~/pages/NotFound/NotFound"));
const LoginCenterPage = lazy(() =>
  import("~/pages/LoginCenterPage/LoginCenterPage")
);

//Componenentes de acordo ao item de menu

// Configuracoes
const CompanyFeaturesContainer = lazy(() =>
  import("~/containers/CompanyFeaturesContainer")
);

// Admnistrador
const AdministratorContainer = lazy(() =>
  import("~/containers/AdministratorContainer")
);
const ManagerAdministratorContainer = lazy(() =>
  import("~/containers/ManagerAdministratorContainer")
);

// Separador
const SeparatorContainer = lazy(() =>
  import("~/containers/SeparatorContainer")
);
const ManagerSeparatorContainer = lazy(() =>
  import("~/containers/ManagerSeparatorContainer")
);

// Cliente
const CustomerContainer = lazy(() => import("~/containers/CustomerContainer"));
const ManagerCustomerContainer = lazy(() =>
  import("~/containers/ManagerCustomerContainer")
);

// Organizacao
const OrganizationContainer = lazy(() =>
  import("~/containers/OrganizationContainer")
);
const ManagerOrganizationContainer = lazy(() =>
  import("~/containers/ManagerOrganizationContainer")
);

// Locker
const ManagerLockerContainer = lazy(() =>
  import("~/containers/ManagerLockerContainer")
);
const LockerContainer = lazy(() => import("~/containers/LockerContainer"));

// Alocacoes
const ManagerAllocationContainer = lazy(() =>
  import("~/containers/ManagerAllocationContainer")
);
const AllocationContainer = lazy(() =>
  import("~/containers/AllocationContainer")
);
// const LockerContainer = lazy(() => import("~/containers/LockerContainer"));

//Rotas globais de nossa aplicação manager.
export default inject(({ authStore, permissionStore }) => ({
  authStore,
  permissionStore,
}))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push("/"));
      };

      props.permissionStore.getOwnPermissions();

      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/error-page" component={NotFound} />
            <Route exact path="/login" render={() => <Redirect to={"/"} />} />
            <Route exact path="/logout" render={() => logout()} />
            <PrivateRoute
              path="/"
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = () => (
  <Switch>
    {/* CONFIGURACOES*/}
    <Route
      exact
      path="/configuracoes/aplicacao"
      component={CompanyFeaturesContainer}
    />

    {/* ADMINISTRADOR */}
    <Route
      exact
      path="/cadastrar-administrador"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/editar-administrador/:uuidAdministrator"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/administradores"
      component={ManagerAdministratorContainer}
    />

    {/* SEPARADOR */}
    <Route exact path="/separadores" component={ManagerSeparatorContainer} />
    <Route exact path="/cadastrar-separador" component={SeparatorContainer} />
    <Route
      exact
      path="/editar-separador/:uuidSeparator"
      component={SeparatorContainer}
    />

    {/* CLIENTES */}

    <Route exact path="/clientes" component={ManagerCustomerContainer} />
    <Route exact path="/cadastrar-cliente" component={CustomerContainer} />
    <Route
      exact
      path="/editar-cliente/:uuidCustomer"
      component={CustomerContainer}
    />

    {/* ORGANIZACAO */}
    <Route
      exact
      path="/cadastrar-organizacao"
      component={OrganizationContainer}
    />
    <Route
      exact
      path="/editar-organizacao/:uuidOrganization"
      component={OrganizationContainer}
    />
    <Route
      exact
      path="/organizacoes"
      component={ManagerOrganizationContainer}
    />

    {/* LOCKER */}
    <Route exact path="/lockers" component={ManagerLockerContainer} />
    <Route
      exact
      path="/editar-locker/:uuidLocker"
      component={LockerContainer}
    />

    {/* ALOCACOES */}
    <Route exact path="/alocacoes" component={ManagerAllocationContainer} />
    <Route exact path="/cadastrar-alocacao" component={AllocationContainer} />
    <Route
      exact
      path="/editar-alocacao/:uuidAllocation"
      component={AllocationContainer}
    />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <LoginCenterPage {...props} />
      )
    }
  />
);
