import BaseAPI, { URLS } from "./BaseAPI";

class UtilsAPI {
  static async listCountries() {
    try {
      const response = await BaseAPI.get(`${URLS.UTILS}/countries`);
      return response.status === 200
        ? response
        : { error: "Falha ao buscar países" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar países" };
    }
  }

 
}

export default UtilsAPI;
