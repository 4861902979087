import BaseAPI, { URLS } from "./BaseAPI";

class AdministratorsAPI {
  /**
   * @param  {Object} data Dados do novo usuário administrador
   */
  static async createAdministrator(data) {
    try {
      const response = await BaseAPI.post(URLS.USER, data);
      return response.status === 201
        ? response.data
        : { error: "Falha ao cadastrar admin" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao cadastrar administrador" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateAdministrator(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.USER}${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do administrador" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async deleteAdministrator(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.USER}${uuid}`);
      if (response.status === 200 || response.status === 204)
        return response.data;
      return { error: "Não foi possível excluir o administrador" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Monta uma query de acordo aos dados  */
  /**
   * @param  {object} prop
   * @param  {valor} value
   */
  static async getAdminBy(prop, value) {
    try {
      const url = `${URLS.USER}${value}/`;
      const params = { by: prop };
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar admin" };
    } catch (e) {
      console.log(e.message);
      return { error: e.message };
    }
  }

  /**
   * Busca um usuário admin por username
   * @param  {string} userName username do usuário a ser buscado
   */
  static async getUserAdmin(userName) {
    try {
      const url = `${URLS.USER}${userName}`;
      const response = await BaseAPI.get(url);
      if (response.data.status) {
        return { error: "Falha ao buscar administrador" };
      }
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar administrador" };
    }
  }

  /**Busca todos os admins */
  static async list(params) {
    try {
      const response = await BaseAPI.get(URLS.USER, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar administrador" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar administrador" };
    }
  }

  static async export(params) {
    try {
      const response = await BaseAPI.get(URLS.USER + "csv", params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }
}

export default AdministratorsAPI;
