import { extendObservable, makeObservable, action } from "mobx";

import SeparatorAPI from "../services/SeparatorAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import SeparatorModel from "~/models/SeparatorModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";

const initValues = {
  separator: undefined,
  separators: [],
  country: undefined,
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,
  loading: false,
};

class SeparatorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.separator = undefined;
    this.separators = [];
    this.country = undefined;
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;
    this.loading = false;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptySeparator() {
    this.separator = new SeparatorModel({});
  }

  onChange(prop, value) {
    if (!this.separator) this.separator = new SeparatorModel();

    switch (prop) {
      case "phone":
        this.separator[prop] = value.replace(/\D/g, "");
        break;

      case "country":
        this.country = value.value
        this.separator['countryCode'] = value.value.phoneDigit;

      default:
        this.separator[prop] = value;
    }
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;

    if (!uuid) uuid = StorageUtil.getItem(KEYS.USER_KEY);

    const response = await SeparatorAPI.getSeparator(uuid);
    this.loading = false;
    if (!response.error) {
      this.separator = new SeparatorModel(response);
      return this.separator;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async getList(params) {
    this.loading = true;

    params = {
      page: params?.page ?? this.page,
      filter: params?.filter ?? '',
    };
    setURLSearchParams(params.page, params.filter)

    const response = await SeparatorAPI.list({ page: params.page, filter: convertObjectToFilter(params.filter) });
    this.loading = false;
    if (!response.error) {
      this.separators = response.content.map(
        (separator) => new SeparatorModel(separator)
      );
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;

      return this.separators;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async export(params) {
    this.loading = true;

    params = {
      filter: convertObjectToFilter(params?.filter),
    };

    const response = await SeparatorAPI.export(params);
    this.loading = false;
    if (!response.error) {
      exportCSV(response, "separadores");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Cadastra um usuario separador.
   */
  async onCreate() {
    this.loading = true;

    const response = await SeparatorAPI.createSeparator(this.separator);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Separador cadastrado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Atualiza dados de um usuario separador.
   * @param  {string} uuid
   */
  async onUpdate(uuid) {
    this.loading = true;

    const response = await SeparatorAPI.updateSeparator(uuid, this.separator);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Separador atualizado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async onDelete(uuid) {
    this.loading = true;

    const response = await SeparatorAPI.deleteSeparator(uuid);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Separador excluído com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}
makeObservable(SeparatorStore, {
});

export default SeparatorStore;
