const CustomerPermissionEnum = Object.freeze({
  GET: "view_customer",
  POST: "add_customer",
  PUT: "change_customer",
  DELETE: "delete_customer",
  EXPORT: "can_export_customer",
  IMPORT: "can_import_customer",
});

export default CustomerPermissionEnum;
