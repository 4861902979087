import BaseAPI, { URLS } from "./BaseAPI";

class OrganizationAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Deleta Organização.
   * @param  {string} uuid da organização
   */
  static async delete(uuid) {
    try {
      const url = `${URLS.ORGANIZATION}/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return { error: "Não foi possível deletar organização" };
    } catch (e) {
      return this._exception(
        e,
        "Não foi possível deletar organização verifique vinculos"
      );
    }
  }

  /**
   * Request que busca organização por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ORGANIZATION}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar organização." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar organização.");
    }
  }

  /**
   * Request que busca organizaçoes filhas que sao final node da organizacao informada
   * @param  {string} uuid - uuid do usuário
   */
  static async getFinalNodes(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORGANIZATION}/${uuid}/final-nodes`
      );
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar organização." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar organização.");
    }
  }

  /**
   * Request que atualiza dados da organização
   * @param  {string} uuid - uuid do usuário
   * @param  {object} data - dados da organização
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ORGANIZATION}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar organização." };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, "Falha ao adicionar organização.");
    }
  }

  /**
   * Request que cria novo organização.
   * @param  {Object} data - Objeto da organização que será criado
   */
  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.ORGANIZATION, data);
      if (response.status === 201) return response.data;
      return { error: "Falha ao cadastrar organização" };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, "Falha ao adicionar organização.");
    }
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getTree(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ORGANIZATION}/tree`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar lista de organizações" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de organizações");
    }
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getOrganizationCustomers(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORGANIZATION}/${uuid}/customers`
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar clientes de organização" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar clientes de organização");
    }
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.ORGANIZATION, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar lista de organizações" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de organizações");
    }
  }

  /**
   * Request que busca organização atribuida ao usuario
   */
  static async getOwn() {
    try {
      const response = await BaseAPI.get(`${URLS.ORGANIZATION}/own`);
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar organização." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar organização.");
    }
  }

  static async export(params) {
    try {
      const response = await BaseAPI.get(URLS.ORGANIZATION + "/csv", params);
      if (response.status === 200) return response.data;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }

  static async import(data) {
    try {
      const response = await BaseAPI.post(URLS.ORGANIZATION + "/csv", data);
      if (response.status === 201) return true;
      return { error: "Erro ao gerar csv" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao gerar csv" };
    }
  }
}

export default OrganizationAPI;
