import UserModel from "./UserModel";

class AdministratorModel extends UserModel {
  constructor(data = {}) {
    super(data);
  }

  get isSuperAdmin() {
    return this.user.role === "STOOM" || this.user.role === "SUPERADMIN";
  }
}

export default AdministratorModel;
